
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.imgZone {
    width: 100%;
    max-width: 329px;
    margin: 0 auto 40px;
    @include lg {
        width: 100%;
        margin: 0;
        margin-left: 50px;
    }
}
